<template>
  <div>
    <!-- 中間區塊 上課紀錄 讀書規劃 聯絡紀錄  -->
    <div class="manager_lesson_infor">
      <ul class="tabs">
        <li class="tab_li active">上課紀錄</li>
      </ul>
      <div class="manager_lesson_infor_container">
        <!-- 上課紀錄 -->
        <div>
          <div class="manager_lesson_infor_title">
            上課紀錄：{{ teacherInfo.name }}老師
          </div>
          <div class="manager_record_search_container">
            <div class="manager_record_search">
              <el-date-picker
                type="month"
                v-model="leasonList.logMonth"
                value-format="yyyy-MM"
                format="yyyy-MM"
                @change="getTeacherList"
                placeholder="選擇月份"
              />
            </div>
          </div>
          <div class="manager_list_title">
            <div>上課日期</div>
            <div>上課時間</div>
            <div>上課科目</div>
            <div>上課回報</div>
          </div>
          <div v-if="teacherRecordsData && teacherRecordsData.length">
            <div
              v-for="(teacher, i) in teacherRecordsData"
              :key="i"
              class="manager_list_infor border_left_right_none"
            >
              <div>{{ teacher.classDate }}</div>
              <div>{{ teacher.classTime }}</div>
              <div>{{ teacher.curriculum }}</div>
              <div>
                <div
                  class="manager_list_infor_btn"
                  @click="openViewlog(1, teacher)"
                >
                  <span
                    v-if="teacher.isReturnInClass && teacher.disPlayerReturn"
                    >已確認</span
                  >
                  <span v-else>查看</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="noDataTab">暫無數據</div>
        </div>

        <div class="paginations">
          <el-pagination
            :hide-on-single-page="true"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="leasonList.pageNum"
            :page-sizes="pageSizeL"
            :page-size="leasonList.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="leasonList.counts"
          ></el-pagination>
        </div>
        <!-- 上課紀錄end -->
      </div>
    </div>
    <!-- 中間區塊 學生列表 老師列表end -->

    <!-- ... pup視窗-上課回報內容 ... -->
    <div class="pup_class_report_t" v-if="tableId == 1">
      <div class="pup_manager_bg" @click="openViewlog(0)"></div>
      <div class="pup_manager_window">
        <div style="padding-bottom: 90px">
          <div class="pup_manager_container">
            <div class="pup_class_report_title">
              {{ teaDetail.classDate }}&nbsp;{{ teaDetail.teacherName }}-{{
                teaDetail.curriculum
              }}上課回報
            </div>
            <div class="pup_class_report_textarea">
              <textarea
                :readonly="true"
                v-model="teaDetail.teacherReturnInClass"
              ></textarea>
            </div>
          </div>
          <img
            @click="openViewlog(0)"
            src="../../assets/image/close_icon.png"
            class="pup_manager_close"
            alt
          />
          <div v-if="teaDetail.isReturnInClass && teaDetail.disPlayerReturn">
            <div class="pup_manager_window_btn window_btn_reture grey">
              退回回報
            </div>
            <div class="pup_manager_window_btn window_btn_send grey">
              核可送出
            </div>
          </div>
          <div v-else>
            <div
              class="pup_manager_window_btn window_btn_reture"
              @click="openViewlog(2)"
            >
              退回回報
            </div>
            <div
              class="pup_manager_window_btn window_btn_send"
              @click="sendBackRecord(1)"
            >
              核可送出
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-上課回報內容end ... -->
    <!-- ... pup視窗-上課回報退回內容 ... -->
    <div class="pup_report_reture" v-if="tableId == 2">
      <div class="pup_manager_bg" @click="openViewlog(0)"></div>
      <div class="pup_manager_window">
        <div style="padding-bottom: 90px">
          <div class="pup_manager_container">
            <div class="pup_class_report_title">退回原因</div>
            <div class="pup_class_report_textarea">
              <textarea v-model="approvalForm.feedback"></textarea>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openViewlog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="sendBackRecord(0)">
            確認退回
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-上課回報內容退回end ... -->
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { getApprovalTeaReturn, getTeaClassOrderList } from "../../api/api";
export default {
  data() {
    return {
      tableId: 0,
      teaDetail: {},
      pageSizeL: [5, 10, 15, 20],
      teacherInfo: { name: "", id: "" },
      teacherRecordsData: [],
      leasonList: { pageNum: 1, pageSize: 10 },
      approvalForm: { orderId: "", disPlayerReturn: false, feedback: "" },
    };
  },
  created() {
    let info = this.$route.query;
    this.teacherInfo = { name: decodeURI(info.name), id: info.id };
    this.getTeacherList();
  },
  methods: {
    openViewlog(id, item) {
      this.tableId = id;
      if (id == 1) {
        this.teaDetail = item;
      }
      if (id == 2) {
        this.approvalForm = {};
      }
    },
    async sendBackRecord(id) {
      if (id) {
        this.approvalForm.disPlayerReturn = true;
      } else {
        this.approvalForm.disPlayerReturn = false;
      }
      this.approvalForm.orderId = this.teaDetail.classOrderId;
      const res = await getApprovalTeaReturn(this.approvalForm);
      if (res && res.code == 200 && res.success == 1) {
        this.tableId = 0;
        this.getTeacherList();
      }
    },
    //上课记录
    async getTeacherList() {
      const res = await getTeaClassOrderList({
        ...this.leasonList,
        teacherId: this.teacherInfo.id,
      });
      if (res && res.code == 200 && res.success == 1) {
        this.teacherRecordsData = res.data.data;
        this.leasonList.counts = res.data.count;
      }
    },
    //分页列表
    handleSizeChange(val) {
      this.leasonList.pageSize = val;
      this.getTeacherList();
    },
    handleCurrentChange(val) {
      this.leasonList.pageNum = val;
      this.getTeacherList();
    },
  },
};
</script>
<style>
@import "../../assets/css/manager_center.css";
.grey {
  background-color: #b5b5b5;
}
.manager_record_search div {
  width: 100%;
  height: 36px;
}

.el-input--prefix .el-input__inner {
  border: 1px solid #9e9e9e !important;
  color: #757575;
}
.el-input__inner::-webkit-input-placeholder {
  color: #757575;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>